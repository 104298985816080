/******************************************************************************
 * Copyright © 2017 XIN Community                                             *
 *                                                                            *
 * See the DEVELOPER-AGREEMENT.txt and LICENSE.txt files at  the top-level    *
 * directory of this distribution for the individual copyright  holder        *
 * information and the developer policies on copyright and licensing.         *
 *                                                                            *
 * Unless otherwise agreed in a custom licensing agreement, no part of the    *
 * XIN software, including this file, may be copied, modified, propagated,    *
 * or distributed except according to the terms contained in the LICENSE.txt  *
 * file.                                                                      *
 *                                                                            *
 * Removal or modification of this copyright notice is prohibited.            *
 *                                                                            *
 ******************************************************************************/


angular.module('baseClient', ['restangular', 'ui.router', 'ui.bootstrap','datatables', 'datatables.bootstrap','cc.autorefresh']);

angular.module('baseClient').constant('baseConfig', {
    'AUTO_PAGE_REFRESH_INTERVAL': window.getEnvConfig('AUTO_PAGE_REFRESH_INTERVAL') || '300000',
    'RELEASE_VERSION': window.getEnvConfig('RELEASE_VERSION'),
    'NETWORK_ENVIRONMENT': window.getEnvConfig('NETWORK_ENVIRONMENT')
});

angular.module('baseClient').filter('isOnline', ['$sce', function ($sce) {
    return function (val) {
        switch (val) {
            case true:
                return '<small> <span class="glyphicon glyphicon-ok" style="color:green"></span> </small>';
            case false:
                return '<small> <span class="glyphicon glyphicon-remove" style="color:red"></span> </small>';
            default:
                return '<small> <span class="glyphicon glyphicon-remove" style="color:black"></span> </small>';
        }
    };
}]);

/******************************************************************************
 * Copyright © 2017 XIN Community                                             *
 *                                                                            *
 * See the DEVELOPER-AGREEMENT.txt and LICENSE.txt files at  the top-level    *
 * directory of this distribution for the individual copyright  holder        *
 * information and the developer policies on copyright and licensing.         *
 *                                                                            *
 * Unless otherwise agreed in a custom licensing agreement, no part of the    *
 * XIN software, including this file, may be copied, modified, propagated,    *
 * or distributed except according to the terms contained in the LICENSE.txt  *
 * file.                                                                      *
 *                                                                            *
 * Removal or modification of this copyright notice is prohibited.            *
 *                                                                            *
 ******************************************************************************/

angular.module('peer', ['baseClient']);

angular.module('peer')
    .config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {

        $stateProvider.state('client.peers', {
            url: '^/monitor',
            templateUrl: './peer/views/monitor.html',
            controller: 'PeerStatusCtrl'
        });
    }]);

var getHostByURL = function(url) {
	if (!url || url === "") {
		return "";
	}
	return new URL(url).host;
}

angular.module('peer').constant('peerEndpoints', [
    {'label': 'Peer Explorer Backend', 'ip': getHostByURL(window.getEnvConfig("PEER_ENDPOINTS_1")) || '35.204.224.241', 'url': window.getEnvConfig("PEER_ENDPOINTS_1") || 'http://35.204.224.241:8888/api/nodes'},
    // {'label': 'Peer #2', 'ip': getHostByURL(window.getEnvConfig("PEER_ENDPOINTS_2")) || '35.204.224.241', 'url': window.getEnvConfig("PEER_ENDPOINTS_2") || 'http://35.204.224.241:8888/api/nodes'},
    // {'label': 'Peer #3', 'ip': getHostByURL(window.getEnvConfig("PEER_ENDPOINTS_3")) || '35.204.224.241', 'url': window.getEnvConfig("PEER_ENDPOINTS_3") || 'http://35.204.224.241:8888/api/nodes'},
    // {'label': 'Peer #1', 'ip': '185.35.137.7'  , 'url': 'http://185.35.137.7:8888/api/nodes'},
    // {'label': 'Peer #2', 'ip': '185.103.75.217', 'url': 'http://185.103.75.217:8888/api/nodes'},
    // {'label': 'Peer #3', 'ip': '185.35.139.101', 'url': 'http://185.35.139.101:8888/api/nodes'},
    // {'label': 'Peer #4', 'ip': '185.35.139.102', 'url': 'http://185.35.139.102:8888/api/nodes'},
    // {'label': 'Peer #5', 'ip': '185.35.139.103', 'url': 'http://185.35.139.103:8888/api/nodes'},
    // {'label': 'Peer #6', 'ip': '185.35.139.104', 'url': 'http://185.35.139.104:8888/api/nodes'},
    // {'label': 'Peer #7', 'ip': '185.35.139.105', 'url': 'http://185.35.139.105:8888/api/nodes'},
    // {'label': 'Peer #8', 'ip': '185.61.149.40' , 'url': 'http://46.244.20.41:8888/api/nodes'},
    // {'label': 'Peer #9', 'ip': '208.95.1.177', 'url': 'http://208.95.1.177:8888/api/nodes'},
    // {'label': 'Peer #10', 'ip': '199.127.137.169', 'url': 'http://199.127.137.169:8888/api/nodes'}
]);

angular.module('peer').constant('macapEndpoints', [
    {'label': 'MaCap', 'ip': getHostByURL(window.getEnvConfig("MACAP_ENDPOINT")) || '185.103.75.217', 'url': window.getEnvConfig("MACAP_ENDPOINT") || 'http://185.103.75.217:8892/api/v1/get'}
]);

angular.module('peer').constant('appEndpoints', [
    // {'label': 'Peerexplorer', 'ip': '185.103.75.217', 'url': 'http://185.103.75.217/peer/images/logo_nav.png'},
    {'label': 'Peer explorer', 'ip': getHostByURL(window.getEnvConfig("APP_ENDPOINTS_PEER")) || '35.204.224.241', 'url': window.getEnvConfig("APP_ENDPOINTS_PEER") || 'http://35.204.224.241/peerexplorer/images/logo_nav.png'},
    // {'label': 'Blockexplorer', 'ip': '185.103.75.217', 'url': 'http://185.103.75.217/block/images/logo_nav.png'},
    {'label': 'Block explorer', 'ip': getHostByURL(window.getEnvConfig("APP_ENDPOINTS_BLOCK")) || '35.204.224.241', 'url': window.getEnvConfig("APP_ENDPOINTS_BLOCK") || 'http://35.204.224.241/peerexplorer/images/logo_nav.png'},
    // {'label': 'Online Wallet #1', 'ip': '46.244.20.41', 'url': 'http://46.244.20.41/wallet/images/logo.png'},
    {'label': 'Online Wallet', 'ip': getHostByURL(window.getEnvConfig("APP_ENDPOINTS_WALLET_1")) || '35.242.201.209', 'url': window.getEnvConfig("APP_ENDPOINTS_WALLET_1") || 'http://35.242.201.209/assets/images/logo.png'},
    {'label': 'Check Balance', 'ip': getHostByURL(window.getEnvConfig("APP_ENDPOINTS_CHECK_BALANCE")) || '35.242.201.209', 'url': window.getEnvConfig("APP_ENDPOINTS_CHECK_BALANCE") || 'http://35.242.201.209/assets/images/logo.png'},
    {'label': 'Online Tools', 'ip': getHostByURL(window.getEnvConfig("APP_ENDPOINTS_CPC_TOOLS")) || '35.242.201.209', 'url': window.getEnvConfig("APP_ENDPOINTS_CPC_TOOLS") || 'http://35.242.201.209/assets/images/logo.png'},
    // {'label': 'Online Wallet #1', 'ip': '185.35.137.7', 'url': 'http://185.35.137.7/wallet/images/logo.png'},
//    {'label': 'Online Wallet #1', 'ip': '208.95.1.177', 'url': 'http://208.95.1.177/wallet/images/logo.png'}
]);
/*
angular.module('peer').constant('testnetEndpoints', [
    {'label': 'Online Wallet', 'ip': getHostByURL(window.getEnvConfig("TESTNET_ENDPOINTS_WALLET")) || '185.35.138.140', 'url': window.getEnvConfig("TESTNET_ENDPOINTS_WALLET") || 'http://185.35.138.140/wallet/images/logo.png'},
    {'label': 'Peerexplorer', 'ip': getHostByURL(window.getEnvConfig("TESTNET_ENDPOINTS_PEER")) || '185.35.138.140', 'url': window.getEnvConfig("TESTNET_ENDPOINTS_PEER") || 'http://185.35.138.140/images/iep-logo.png'},
    {'label': 'Blockexplorer', 'ip': getHostByURL(window.getEnvConfig("TESTNET_ENDPOINTS_BLOCK")) || '185.35.138.140', 'url': window.getEnvConfig("TESTNET_ENDPOINTS_BLOCK") || 'http://185.35.138.140/block/images/logo_nav.png'}
]);

angular.module('peer').constant('apiEndpoints', [
  {'label': 'API Node', 'ip': getHostByURL(window.getEnvConfig("API_ENDPOINTS_NODE")) || '199.127.137.169', 'url': window.getEnvConfig("API_ENDPOINTS_NODE") || 'http://199.127.137.169:9005/docs/images/favicon-16x16.png'},
  {'label': 'API Backends', 'ip': getHostByURL(window.getEnvConfig("API_ENDPOINTS_BACKEND")) || '199.127.137.169', 'url': window.getEnvConfig("API_ENDPOINTS_BACKEND") || 'http://199.127.137.169:9006/docs/images/favicon-16x16.png'}
]);
*/
angular.module('peer').constant('apiEndpoints', [
//  {'label': 'API Node', 'ip': getHostByURL(window.getEnvConfig("API_NODE")) || '199.127.137.169', 'url': window.getEnvConfig("API_NODE") || 'http://199.127.137.169:9005/docs/images/favicon-16x16.png'},
    {'label': 'API Node', 'ip': getHostByURL(window.getEnvConfig("API_NODE")) || '199.127.137.169', 'url': window.getEnvConfig("API_NODE") || 'http://199.127.137.169:9006/docs/images/favicon-16x16.png'}
]);

angular.module('peer').constant('peerConfig', {
    'apiUrl': 'api/nodes',
});

/******************************************************************************
 * Copyright © 2017 XIN Community                                             *
 *                                                                            *
 * See the DEVELOPER-AGREEMENT.txt and LICENSE.txt files at  the top-level    *
 * directory of this distribution for the individual copyright  holder        *
 * information and the developer policies on copyright and licensing.         *
 *                                                                            *
 * Unless otherwise agreed in a custom licensing agreement, no part of the    *
 * XIN software, including this file, may be copied, modified, propagated,    *
 * or distributed except according to the terms contained in the LICENSE.txt  *
 * file.                                                                      *
 *                                                                            *
 * Removal or modification of this copyright notice is prohibited.            *
 *                                                                            *
 ******************************************************************************/

angular.module('peer')
    .service('PeerService', ['Restangular', '$q', function (Restangular, $q) {

        this.getPeerStatus = function (url, website) {
            if (!website) {
                Restangular.setBaseUrl(url);
                return Restangular.all('').one('').get();
            } else {
                return $q(function (resolve, reject) {
                    var image = new Image();
                    image.src = url;
                    image.onload = function () {
                        resolve(image);
                    };
                    image.onerror = function (e) {
                        reject(e);
                    };
                });
            }

        };

    }]);

/******************************************************************************
 * Copyright © 2017 XIN Community                                             *
 *                                                                            *
 * See the DEVELOPER-AGREEMENT.txt and LICENSE.txt files at  the top-level    *
 * directory of this distribution for the individual copyright  holder        *
 * information and the developer policies on copyright and licensing.         *
 *                                                                            *
 * Unless otherwise agreed in a custom licensing agreement, no part of the    *
 * XIN software, including this file, may be copied, modified, propagated,    *
 * or distributed except according to the terms contained in the LICENSE.txt  *
 * file.                                                                      *
 *                                                                            *
 * Removal or modification of this copyright notice is prohibited.            *
 *                                                                            *
 ******************************************************************************/

angular.module('peer').controller('PeerStatusCtrl',
    ['$scope', 'PeerService', 'peerEndpoints', 'macapEndpoints', 'appEndpoints', 'apiEndpoints', 'isOnlineFilter', '$sce',
        function ($scope, PeerService, peerEndpoints, macapEndpoints, appEndpoints, apiEndpoints, isOnlineFilter, $sce) {

            $scope.peerEndpoints = peerEndpoints;
            $scope.macapEndpoints = macapEndpoints;
            $scope.appEndpoints = appEndpoints;
            $scope.apiEndpoints = apiEndpoints;

            $scope.trustAsHtml = $sce.trustAsHtml;

            $scope.IP_STATUS = {};

            $scope.getAllIpStatus = function (ipArray,website) {

                angular.forEach(ipArray, function (value, key) {
                    PeerService.getPeerStatus(value.url,website).then(function (success) {
                        //console.log(success);
                        $scope.IP_STATUS[value.url] = $scope.IP_STATUS[value.url] || {};
                        $scope.IP_STATUS[value.url].status = '<span class="icon-check2"></span>';
                        $scope.IP_STATUS[value.url].timestamp = new Date().toString();
                    }, function (error) {
                        $scope.IP_STATUS[value.url] = $scope.IP_STATUS[value] || {};
                        $scope.IP_STATUS[value.url].status = '<span class="label label-warning">OFFLINE</span>';
                        $scope.IP_STATUS[value.url].timestamp = new Date().toString();
                    });
                });
            };

            $scope.getStatus = function (check, website) {

                var url = check.url;
                $scope.IP_STATUS[url] = {};
                return PeerService.getPeerStatus(url,website).then(function (success) {
                    $scope.IP_STATUS[url] = $scope.IP_STATUS[url] || {};
                    $scope.IP_STATUS[url].status = '<span class="icon-check2"></span>';
                    $scope.IP_STATUS[url].timestamp = new Date().toString();
                }, function (error) {
                    console.log({error})
                    $scope.IP_STATUS[url] = $scope.IP_STATUS[url] || {};
                    $scope.IP_STATUS[url].status = '<span class="label label-warning">OFFLINE</span>';
                    $scope.IP_STATUS[url].timestamp = new Date().toString();
                });
            };

        }]);

/******************************************************************************
 * Copyright © 2017 XIN Community                                             *
 *                                                                            *
 * See the DEVELOPER-AGREEMENT.txt and LICENSE.txt files at  the top-level    *
 * directory of this distribution for the individual copyright  holder        *
 * information and the developer policies on copyright and licensing.         *
 *                                                                            *
 * Unless otherwise agreed in a custom licensing agreement, no part of the    *
 * XIN software, including this file, may be copied, modified, propagated,    *
 * or distributed except according to the terms contained in the LICENSE.txt  *
 * file.                                                                      *
 *                                                                            *
 * Removal or modification of this copyright notice is prohibited.            *
 *                                                                            *
 ******************************************************************************/

angular.module('monitoring', ['peer']);

angular.module('monitoring')
    .config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
        $stateProvider.state('client', {
            abstract: true,
            url: '/',
            template: '<div ui-view></div>'
        });

        $urlRouterProvider.otherwise('/monitor');

        document.title = "CPCoin - New Service Monitor | " + window.getEnvConfig("NETWORK_ENVIRONMENT");
    }]);

angular.module('monitoring').run(['baseConfig', '$rootScope', function (baseConfig, $rootScope) {
    $rootScope.MONITOR_OPTIONS = baseConfig;
}]);
